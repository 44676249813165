import { AxiosError } from 'axios';
import { OptionsWithExtraProps, useSnackbar } from 'notistack';
import toast, { Toaster } from 'react-hot-toast';
const useNotistack = () => {
    const showError = (message: string) => {
        toast.error(message, {
            style: {
                background: '#880000',
                color: 'white',
            },
        });
    };

    const showAxiosError = (error: AxiosError) => {
        toast.error(error.response?.data.message || error.message, {
            style: {
                background: '#880000',
                color: 'white',
                zIndex: 2000,
            },
        });
    };

    const showSuccess = (message: string) => {
        toast.success(message, {
            style: {
                background: '#008800',
                color: 'white',
                zIndex: 2000,
            },
        });
    };

    const showWarning = (message: string, options?: Partial<{ persist: boolean; autoHideDuration: number }>) => {
        toast(message, {
            icon: '⚠️',
            style: {
                background: '#888800',
                color: 'white',
                zIndex: 2000,
            },
        });
    };

    return {
        showError,
        showAxiosError,
        showSuccess,
        showWarning,
        Toaster,
    };
};

export default useNotistack;
