import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { getAvailabilityBySetterTeamId } from '../../services/teams.service';
import moment from 'moment';
import { Box, Button, Chip, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import { time24to12 } from 'react-helpers';

const AvailableTimeSlotsField = () => {
    const { values, setFieldValue } = useFormikContext<{
        appointmentDate: string;
        startTime: string;
        setterTeamId: number;
    }>();

    const { data: availableTimeSlots, isLoading } = useQuery(
        ['availableTimeSlots', values.appointmentDate],
        () =>
            getAvailabilityBySetterTeamId({
                id: Number(values.setterTeamId),
                appointmentDate: moment.utc(values.appointmentDate).toISOString(),
            }),
        {
            enabled: !!values.setterTeamId && !!values.appointmentDate,
            keepPreviousData: false,
        },
    );
    const theme = useTheme();

    if (!values.appointmentDate && !values.setterTeamId)
        return (
            <Box mt={2}>
                <Typography color="error">Please select a Consultation Date.</Typography>
            </Box>
        );
    if (!values.appointmentDate)
        return (
            <Box mt={2}>
                <Typography color="error">Please select an Appointment Date to view time slots.</Typography>
            </Box>
        );
    return (
        <>
            {isLoading ? (
                <Box display={'flex'} flexDirection={'column'} sx={{ overflowX: 'auto', maxWidth: '100%' }} mt={2}>
                    {Array.from({ length: 10 }).map((_, i) => (
                        <TimeSlotLoader key={i} />
                    ))}
                </Box>
            ) : (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    sx={{ overflowX: 'auto', maxWidth: '100%' }}
                    mt={2}
                >
                    {availableTimeSlots &&
                        availableTimeSlots.length > 0 &&
                        availableTimeSlots
                            .sort((a, b) => a.startTime.localeCompare(b.startTime))
                            .map((t) => (
                                <Button
                                    onClick={() => {
                                        setFieldValue('startTime', t.startTime);
                                        setFieldValue('endTime', t.endTime);
                                    }}
                                    variant={t.startTime === values.startTime ? 'contained' : 'outlined'}
                                    disabled={t.availableSlots === 0}
                                    key={t.appointmentDate + t.startTime}
                                    sx={{
                                        width: '100%',
                                        textTransform: 'none',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <Typography fontSize={16}>{time24to12(t.startTime)}</Typography>
                                        {/* <Chip
                                            label={t.availableSlots + ' available'}
                                            size="small"
                                            color={t.availableSlots > 0 ? 'success' : 'error'}
                                            disabled={t.availableSlots === 0}
                                        /> */}
                                    </Box>
                                </Button>
                            ))}
                </Box>
            )}
        </>
    );
};

const TimeSlotLoader = () => {
    return (
        <Skeleton
            sx={{
                width: '100%',
                height: '60px',
                display: 'inline-block',
            }}
        ></Skeleton>
    );
};

export default AvailableTimeSlotsField;
