import { Formik } from 'formik';
import React from 'react';
import { Box, Divider } from '@mui/material';
import SunlyLogo from '../../assets/images/sunly-logo.png';
import LowesLogo from '../../assets/images/lowes-logo.png';

const TopBanner = () => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center" gap={4} my={4}>
            <img src={LowesLogo} style={{ width: 100 }} />
            {/* <Divider orientation="vertical" flexItem />
            <img src={SunlyLogo} style={{ width: 100 }} /> */}
        </Box>
    );
};

export default TopBanner;
