import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import TopBanner from './TopBanner';
import { Form, Formik } from 'formik';
import FormikVitlDateField from '../../components/forms/FormikVitlDateField';
import moment from 'moment';
import { Box, Paper, Slide, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getPublicLead, rescheduleAppointmentFromHomeownerForm } from '../../services/leads.service';
import AvailableTimeSlotsField from './AvailableTimeSlotsField';
import { getCurrentAppointment } from 'shared/src/leads';
import { Appointment, Lead } from 'database';
import { time24to12, useGridSystem } from 'react-helpers';
import FormikDebugger from '../../components/forms/FormikDebugger';
import { FormikVitlTextField, SubmitButton } from 'react-helpers/src/components/forms';
import useNotistack from 'react-helpers/src/hooks/useNotistack';

interface ScheduleAppointmentProps {
    leadId: string;
    appointment?: Appointment;
    onSuccess?: () => void;
}

const ScheduleAppointment = ({ leadId, onSuccess }: ScheduleAppointmentProps) => {
    const {
        data: lead,
        isFetched,
        isLoading: isLoadingTimeslots,
    } = useQuery(['lead', leadId], () => getPublicLead(leadId));
    const queryClient = useQueryClient();
    const { showSuccess } = useNotistack();
    const { mutate: scheduleAppointmentMutation, isLoading: isSchedulingAppointment } = useMutation(
        rescheduleAppointmentFromHomeownerForm,
        {
            onSuccess: (data) => {
                showSuccess('Appointment rescheduled successfully');
                onSuccess?.();
                queryClient.invalidateQueries(['lead', leadId]);
            },
        },
    );

    console.log(lead);
    const { sm } = useGridSystem();

    if (!isFetched) return <Box sx={{ textAlign: 'center', p: 2 }}>Loading...</Box>;
    return (
        <Box p={2}>
            <>
                <Formik
                    initialValues={{
                        appointmentDate: '',
                        setterTeamId: lead?.setterTeamId,
                        startTime: '',
                        endTime: '',
                        rescheduleReason: '',
                    }}
                    onSubmit={(values) => {
                        scheduleAppointmentMutation({
                            publicId: lead?.publicId,
                            body: {
                                appointmentDate: moment.utc(values.appointmentDate).toDate(),
                                startTime: values.startTime,
                                endTime: values.endTime,
                                rescheduleReason: values.rescheduleReason || 'No reason provided',
                            },
                        });
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <FormikDebugger />
                            <Box mb={2}>
                                <FormikVitlTextField
                                    name="rescheduleReason"
                                    label="Reschedule Reason"
                                    multiline
                                    minRows={3}
                                />
                            </Box>
                            <FormikVitlDateField
                                name="appointmentDate"
                                label="Select an Consultation Date"
                                textFieldProps={{}}
                                shouldDisableDate={(date) => {
                                    return moment().add(1, 'day').startOf('day').isAfter(date);
                                }}
                            />
                            <AvailableTimeSlotsField />
                            <Slide
                                direction="up"
                                in={!!values.appointmentDate && !!values.startTime && !isLoadingTimeslots}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Box position="sticky" bottom={0} width="100%" p={2}>
                                    <SubmitButton isLoading={isSchedulingAppointment}>
                                        {sm ? 'Tap' : 'Click'} here to schedule for{' '}
                                        {moment.utc(values.appointmentDate).format('ddd, MMM D')} at{' '}
                                        {time24to12(values.startTime)}
                                    </SubmitButton>
                                </Box>
                            </Slide>
                        </Form>
                    )}
                </Formik>
            </>
        </Box>
    );
};

export default ScheduleAppointment;
