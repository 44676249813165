import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Form, Formik } from 'formik';
import FormikVitlDateField from '../../components/forms/FormikVitlDateField';
import moment from 'moment';
import { Box, Paper, Slide, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cancelAppointment, getPublicLead, rescheduleAppointmentFromHomeownerForm } from '../../services/leads.service';
import { getCurrentAppointment } from 'shared/src/leads';
import { Appointment, Lead } from 'database';
import { time24to12, useGridSystem } from 'react-helpers';
import FormikDebugger from '../../components/forms/FormikDebugger';
import { FormikVitlTextField, SubmitButton } from 'react-helpers/src/components/forms';
import useNotistack from 'react-helpers/src/hooks/useNotistack';
interface CancelAppointmentProps {
    appointment: Appointment;
    publicId: string;
    onSuccess?: () => void;
}

const CancelAppointment = ({ appointment, publicId, onSuccess }: CancelAppointmentProps) => {
    const { showSuccess } = useNotistack();
    const queryClient = useQueryClient();
    const { mutate: cancelAppoinmentMutation, isLoading: isCancellingAppointment } = useMutation({
        mutationFn: cancelAppointment,
        onSuccess: () => {
            showSuccess('Appointment canceled successfully');
            onSuccess?.();
            queryClient.invalidateQueries(['lead', publicId]);
        },
    });
    const { sm } = useGridSystem();
    return (
        <Box p={2}>
            <>
                <Formik
                    initialValues={{
                        publicId,
                        cancellationReason: '',
                    }}
                    onSubmit={(values) => {
                        cancelAppoinmentMutation({
                            publicId,
                            body: {
                                reason: values.cancellationReason,
                            },
                        });
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <FormikDebugger />
                            <Box mb={1}>
                                <FormikVitlTextField
                                    name="cancellationReason"
                                    label="Cancellation Reason"
                                    multiline
                                    minRows={3}
                                />
                            </Box>
                            <SubmitButton isLoading={isCancellingAppointment} disabled={!values.cancellationReason}>
                                Cancel Appointment
                            </SubmitButton>
                        </Form>
                    )}
                </Formik>
            </>
        </Box>
    );
};

export default CancelAppointment;
