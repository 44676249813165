import {
    Dialog,
    AppBar,
    Box,
    IconButton,
    Typography,
    useTheme,
    useMediaQuery,
    CircularProgress,
    DialogProps,
    PaperProps,
    Paper,
    Slide,
    SlideProps,
    AppBarProps,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useWindowSize } from '../hooks/useWindowSize';
import { BOTTOM_NAV_Z_INDEX } from '../styles/styles';

const Transition = React.forwardRef(function Transition(props: SlideProps, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface IModalProps {
    open: boolean;
    onClose?: () => void;
    setOpen?: (isOpen: boolean) => void;
    children: ReactNode;
    title: string;
    DialogProps?: Partial<DialogProps>;
    AppBarProps?: Partial<AppBarProps>;
    PaperProps?: Partial<PaperProps>;
    rightNode?: ReactNode;
    showCloseIcon?: boolean;
    rest?: any;
    closeIcon?: ReactNode;
    fullScreen?: boolean;
}

export const Modal = ({
    open,
    onClose,
    setOpen,
    children,
    title,
    DialogProps,
    PaperProps,
    AppBarProps,
    rightNode,
    showCloseIcon = true,
    closeIcon,
    fullScreen,
    ...rest
}: IModalProps) => {
    const theme = useTheme();
    const { isMobile } = useWindowSize();
    return (
        <Dialog
            fullWidth
            fullScreen={fullScreen || isMobile}
            open={open}
            onClose={() => {
                onClose && onClose();
                setOpen?.(false);
            }}
            TransitionComponent={Transition}
            style={{
                zIndex: BOTTOM_NAV_Z_INDEX + 1,
            }}
            {...DialogProps}
            {...PaperProps}
            {...rest}
        >
            <AppBar style={{ position: 'sticky', backgroundColor: theme.palette.background.default }} {...AppBarProps}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        {showCloseIcon && (
                            <Box paddingLeft={2}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => {
                                        onClose && onClose();
                                        setOpen?.(false);
                                    }}
                                >
                                    {closeIcon ?? <CloseIcon />}
                                </IconButton>
                            </Box>
                        )}
                        <Box py={2} paddingLeft={showCloseIcon ? 0 : 2}>
                            <Typography>{title}</Typography>
                        </Box>
                    </Box>
                    <Box flexGrow={1} textAlign="right" px={2}>
                        {rightNode}
                    </Box>
                </Box>
            </AppBar>
            <Box>{children}</Box>
        </Dialog>
    );
};
