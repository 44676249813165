import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getPublicLead } from '../../services/leads.service';
import { useParams } from 'react-router-dom';
import TopBanner from './TopBanner';
import { Box, Typography } from '@mui/material';

const PostNewLeadSuccess = () => {
    const { leadId } = useParams();
    const { data } = useQuery(['lead', leadId], () => getPublicLead(leadId));
    return (
        <div>
            <TopBanner />
            <Box p={2} textAlign="center">
                <Typography variant="h4">Thank you for signing up!</Typography>
                <Typography variant="h6" mt={6}>
                    <u>Please continue the conversation with your representative to schedule your appointment.</u>
                </Typography>
                <Typography variant="h6" mt={6}>
                    You may now close this window.
                </Typography>
            </Box>
        </div>
    );
};

export default PostNewLeadSuccess;
