import Yup from '../../yup';

export const OnboardingEmergencyContactFormSchema = Yup.object({
    emergencyContactName: Yup.string()
        .nullable()
        .castToEmptyStringIfNull()
        .required('Emergency Contact Name is required.'),
    emergencyContactPhone: Yup.string()
        .nullable()
        .castToEmptyStringIfNull()
        .required('Emergency Contact Phone is required.'),
    emergencyContactRelation: Yup.string()
        .nullable()
        .castToEmptyStringIfNull()
        .required('Emergency Contact Relationship is required.'),
});
