import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { getPublicLead } from '../../services/leads.service';
import { useParams } from 'react-router-dom';
import { Box, Typography, Paper, Button, CircularProgress, Alert } from '@mui/material';
import TopBanner from '../lead-gen-qr/TopBanner';
import MuxPlayer from '@mux/mux-player-react';
import { atcb_action } from 'add-to-calendar-button-react';
import { Loader, Modal, time24to12 } from 'react-helpers';
import moment from 'moment';
import { CalendarToday, EditCalendar, Event, EventBusy } from '@mui/icons-material';
import ScheduleAppointment from '../lead-gen-qr/ScheduleAppointment';
import { getCurrentAppointment } from 'shared/src/leads';
import { Lead } from 'database';
import CancelAppointment from './CancelAppointment';

const CANCELLED_DISPOSITION_ID = 8;

const CustomerPortal = () => {
    const { publicLeadId } = useParams();

    const { data: lead } = useQuery(['lead', publicLeadId], () => getPublicLead(publicLeadId));
    const appointment = useMemo(() => {
        if (!lead) return null;
        return getCurrentAppointment(lead as Lead);
    }, [lead]);

    const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    if (!lead) {
        return <Loader />;
    }

    const isCancelled = appointment?.dispositionId === CANCELLED_DISPOSITION_ID;

    return (
        <Box>
            <TopBanner />
            <Box px={2} mb={2}>
                {isCancelled && (
                    <Box mb={2}>
                        <Alert severity="warning">
                            This appointment has been canceled.{' '}
                            <u>If you would like to reschedule, use the "Reschedule Appointment" button below.</u>
                        </Alert>
                    </Box>
                )}
                <Paper>
                    <Box p={2}>
                        <Box>
                            <Typography>
                                {lead?.firstName} {lead?.lastName}
                            </Typography>
                            <Typography>{lead.streetAddress1}</Typography>
                            <Typography>
                                {lead.city}, {lead.state} {lead.zip}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Typography variant="h6" style={{ fontWeight: 700 }}>
                                Appointment Details
                            </Typography>
                            <Typography>
                                {moment.utc(appointment.sitDate).format('dddd, MMMM Do, YYYY')} at{' '}
                                {time24to12(appointment.startTime)}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <Box px={2}>
                {!isCancelled && (
                    <Box my={2} display="flex" alignItems="center" justifyContent="center">
                        <Button
                            startIcon={<Event />}
                            variant="contained"
                            fullWidth
                            disableElevation
                            style={{ textTransform: 'none' }}
                            color="success"
                            onClick={() => {
                                atcb_action({
                                    name: `Solar Consultation`,
                                    startDate: moment.utc(appointment.sitDate).format('YYYY-MM-DD'),
                                    endDate: moment.utc(appointment.sitDate).format('YYYY-MM-DD'),
                                    startTime: appointment.startTime,
                                    endTime: appointment.endTime,
                                    options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    iCalFileName: 'Reminder-Event',
                                });
                            }}
                        >
                            <Typography variant="h6" style={{ fontWeight: 700 }}>
                                Add to Calendar
                            </Typography>
                        </Button>
                    </Box>
                )}
                <Box my={2} display="flex" alignItems="center" justifyContent="center">
                    <Modal
                        title={`Reschedule: ${lead.fullName}`}
                        open={rescheduleModalOpen}
                        onClose={() => setRescheduleModalOpen(false)}
                    >
                        {appointment && (
                            <ScheduleAppointment
                                leadId={lead.publicId}
                                onSuccess={() => setRescheduleModalOpen(false)}
                            />
                        )}
                    </Modal>
                    <Button
                        startIcon={<EditCalendar />}
                        variant="contained"
                        fullWidth
                        disableElevation
                        style={{ textTransform: 'none' }}
                        color="primary"
                        onClick={() => {
                            setRescheduleModalOpen(true);
                        }}
                    >
                        <Typography variant="h6" style={{ fontWeight: 700 }}>
                            Reschedule Appointment
                        </Typography>
                    </Button>
                </Box>
                {!isCancelled && (
                    <Box my={2} display="flex" alignItems="center" justifyContent="center">
                        <Modal
                            title={`Cancel Appointment: ${lead.fullName}`}
                            open={cancelModalOpen}
                            onClose={() => setCancelModalOpen(false)}
                        >
                            {appointment && (
                                <CancelAppointment
                                    appointment={appointment}
                                    publicId={lead.publicId}
                                    onSuccess={() => setCancelModalOpen(false)}
                                />
                            )}
                        </Modal>
                        <Button
                            startIcon={<EventBusy />}
                            variant="contained"
                            fullWidth
                            disableElevation
                            style={{ textTransform: 'none' }}
                            color="error"
                            onClick={() => {
                                setCancelModalOpen(true);
                            }}
                        >
                            <Typography variant="h6" style={{ fontWeight: 700 }}>
                                Cancel Appointment
                            </Typography>
                        </Button>
                    </Box>
                )}
            </Box>
            <Box px={2} mb={4}>
                <MuxPlayer
                    streamType="on-demand"
                    playbackId={'OLkpmRrJrpiS8Gb0094Ic01MQATxdMTijfwu5moFiGL800'}
                    primaryColor="#FFFFFF"
                    secondaryColor="#000000"
                    minResolution="1080p"
                    maxResolution="1080p"
                    thumbnailTime={2}
                />
            </Box>
        </Box>
    );
};

export default CustomerPortal;
