import Yup from '../../yup';

export const UserBankingSchema = Yup.object({
    bankName: Yup.string().nullable().castToEmptyStringIfNull().required('Bank Name is required.'),
    routingNumber: Yup.string()
        .nullable()
        .castToEmptyStringIfNull()
        .required('Routing Number is required.')
        .length(9, 'Routing Number must be exactly 9 digits.')
        .matches(/^[0-9]+$/, 'Routing Number must only contain digits.'),
    accountNumber: Yup.string()
        .nullable()
        .castToEmptyStringIfNull()
        .required('Account Number is required.')
        .max(17, 'Account Number must be at most 17 characters.')
        .matches(/^[0-9]+$/, 'Account Number must only contain digits.'),
    confirmAccountNumber: Yup.string()
        .nullable()
        .castToEmptyStringIfNull()
        .oneOf([Yup.ref('accountNumber')], 'Account numbers must match.')
        .nullable(),
    accountType: Yup.string().nullable().castToEmptyStringIfNull().required('Account Type is required.'),
});
