import Yup from '../../yup';

export const OnboardingSwagFormSchema = Yup.object({
    shirtSize: Yup.string().nullable().castToEmptyStringIfNull().required('Shirt Size is required.'),
    pantSize: Yup.string().nullable().castToEmptyStringIfNull().required('Pant Size is required.'),
    poloSize: Yup.string().nullable().castToEmptyStringIfNull().required('Polo Size is required.'),
    shoeSize: Yup.string()
        .nullable()
        .castToEmptyStringIfNull()
        .required('Shoe Size is required.')
        .matches(/^(1[0-5]|[6-9])(\.[0-9])? [MF]$/),
});
