import Yup from '../../yup';

export const UserInfoSchema = Yup.object().shape({
    firstName: Yup.string()
        .nullable()
        .required('First Name is required.')
        .max(50, 'First Name must be at most 50 characters.'),
    lastName: Yup.string()
        .nullable()
        .required('Last Name is required.')
        .max(50, 'Last Name must be at most 50 characters.'),
    phone: Yup.string()
        .nullable()
        .required('Phone number is required.')
        .matches(/^[0-9]+$/, 'Phone number must only contain digits.'),
    email: Yup.string().nullable().required('Email is required.'),
    isActive: Yup.boolean().nullable().required('Is Active is required.'),
    freedomPortalId: Yup.number().nullable(),
    recruiterId: Yup.string().nullable().castToEmptyStringIfNull(),
});
