import {
    AppointmentTimeSlot,
    CreateAppointmentTimeSlotDto,
    CreateTeamDto,
    GroupMeTeam,
    OverrideCalendarDays,
    Team,
    TeamCanCloseFor,
    UpdateTeamDto,
} from 'database';
import { formQueryString, http } from '../http/http';
import {
    AvailableTimeSlotsDto,
    BatchProcessOperationsDto,
    GetTeamFreedomDiffResponse,
    GetTeamsQueryParams,
    OverrideCalendarDaysDto,
    PaginatedQueryResponse,
    PublicAvailableTimeSlotsDto,
    TeamHierarchy,
} from 'shared';

const ENDPOINT = 'teams';

export const postTeam = async (body: CreateTeamDto): Promise<Team> => {
    const { data: response } = await http.post<Team>(`${ENDPOINT}`, body);
    return response;
};

export const getTeams = async (args: GetTeamsQueryParams): Promise<PaginatedQueryResponse<Team>> => {
    const { data: teams } = await http.get(`${ENDPOINT}${formQueryString(args)}`);
    return teams;
};

export const getTeamById = async (id: number): Promise<Team> => {
    const { data: team } = await http.get(`${ENDPOINT}/${id}`);
    return team;
};

export type PatchTeamArgs = {
    id: number;
    body: Partial<UpdateTeamDto>;
};

export const patchTeam = async (args: PatchTeamArgs): Promise<Team> => {
    const { data: team } = await http.patch(`${ENDPOINT}/${args.id}`, args.body);
    return team;
};

export const getTeamHierarchy = async (id: number): Promise<TeamHierarchy[]> => {
    const { data: hierarchy } = await http.get(`${ENDPOINT}/${id}/hierarchy`);
    return hierarchy;
};

export const getTeamFreedomDiff = async (id: number): Promise<GetTeamFreedomDiffResponse> => {
    const { data: diff } = await http.get(`${ENDPOINT}/${id}/freedomDiff`);
    return diff;
};

export const batchProcessTeamOperations = async (body: BatchProcessOperationsDto): Promise<void> => {
    await http.post(`${ENDPOINT}/batchProcessOperations`, body);
};

export const getGroupMeTeamsByTeamId = async (id: number): Promise<GroupMeTeam[]> => {
    const { data: teams } = await http.get(`${ENDPOINT}/${id}/groupMeTeams`);
    return teams;
};

export const getTimeSlots = async (id: number): Promise<AppointmentTimeSlot[]> => {
    const { data: timeSlots } = await http.get(`${ENDPOINT}/${id}/timeSlots`);
    return timeSlots;
};

export type PatchTimeSlotsArgs = {
    id: number;
    body: CreateAppointmentTimeSlotDto[];
};

export const patchTimeSlots = async (args: PatchTimeSlotsArgs): Promise<CreateAppointmentTimeSlotDto[]> => {
    const { data: timeSlots } = await http.patch(`${ENDPOINT}/${args.id}/timeSlots`, args.body);
    return timeSlots;
};

export const getTeamRelationships = async (
    id: number,
): Promise<{
    canCloseForTeams: TeamCanCloseFor[];
    canBeClosedByTeam: TeamCanCloseFor[];
}> => {
    const { data: relationships } = await http.get(`${ENDPOINT}/${id}/teamRelationships`);
    return relationships;
};

export type PatchTeamRelationshipsArgs = {
    id: number;
    body: {
        canCloseForTeams: number[];
        canBeClosedByTeams: number[];
    };
};

export const patchTeamRelationships = async (args: PatchTeamRelationshipsArgs): Promise<void> => {
    await http.patch(`${ENDPOINT}/${args.id}/teamRelationships`, args.body);
};

export type GetAvailableTimeSlotsArgs = {
    id: number;
    /**
     * appointmentDate is ISO 8601 formatted date string with no time and timezone set to UTC. For example to pass in 2024-01-01 use 2024-01-01T00:00:00Z
     */
    appointmentDate: string;
};

export const getAvailableTimeSlots = async (args: GetAvailableTimeSlotsArgs): Promise<AvailableTimeSlotsDto[]> => {
    const { data: timeSlots } = await http.get<AvailableTimeSlotsDto[]>(`${ENDPOINT}/${args.id}/availableTimeSlots`, {
        params: {
            appointmentDate: args.appointmentDate,
        },
    });
    return timeSlots;
};

export const getNextAvailableTimeSlot = async (id: number): Promise<AvailableTimeSlotsDto> => {
    const { data: timeSlots } = await http.get<AvailableTimeSlotsDto>(`${ENDPOINT}/${id}/nextAvailableTimeSlot`);
    return timeSlots;
};

export type GetOverrideCalendarDaysArgs = {
    id: number;
    /**
     * formatted YYYY-MM-DD
     */
    startAppointmentDate?: string;
    /**
     * formatted YYYY-MM-DD
     */
    endAppointmentDate?: string;
};

export const getOverrideCalendarDays = async (args: GetOverrideCalendarDaysArgs) => {
    const { data: overrideCalendarDays } = await http.get<OverrideCalendarDays[]>(
        `${ENDPOINT}/${args.id}/overrideCalendarDays`,
        {
            params: {
                startAppointmentDate: args.startAppointmentDate,
                endAppointmentDate: args.endAppointmentDate,
            },
        },
    );

    return overrideCalendarDays;
};

export type PostOverrideCalendarDaysArgs = {
    id: number;
    body: OverrideCalendarDaysDto;
};

export const postOverrideCalendarDays = async (args: PostOverrideCalendarDaysArgs) => {
    const { data: overrideCalendarDays } = await http.post(`${ENDPOINT}/${args.id}/overrideCalendarDays`, args.body);
    return overrideCalendarDays;
};

export type MarkAllAsZeroArgs = {
    id: number;
    body: {
        /**
         * formatted YYYY-MM-DD
         */
        date: string;
    };
};

export const markAllAsZero = async (args: MarkAllAsZeroArgs) => {
    const { data: overrideCalendarDays } = await http.post(
        `${ENDPOINT}/${args.id}/overrideCalendarDays/markAllAsZero`,
        args.body,
    );
    return overrideCalendarDays;
};

export type DeleteAllOverridesArgs = {
    id: number;
    body: {
        /**
         * formatted YYYY-MM-DD
         */
        date: string;
    };
};

export const deleteAllOverrides = async (args: DeleteAllOverridesArgs) => {
    await http.post(`${ENDPOINT}/${args.id}/overrideCalendarDays/deleteAll`, args.body);
};

export type DeleteOverrideArgs = {
    id: number;
    overrideId: string;
};

export const deleteOverride = async (args: DeleteOverrideArgs) => {
    await http.delete(`${ENDPOINT}/${args.id}/overrideCalendarDays/${args.overrideId}`);
};

export type GetAvailabilityBySetterTeamIdArgs = {
    id: number;
    /**
     * appointmentDate is ISO 8601 formatted date string with no time and timezone set to UTC. For example to pass in 2024-01-01 use 2024-01-01T00:00:00Z
     */
    appointmentDate: string;
};

export const getAvailabilityBySetterTeamId = async (
    args: GetAvailabilityBySetterTeamIdArgs,
): Promise<PublicAvailableTimeSlotsDto[]> => {
    const { data: timeSlots } = await http.get<PublicAvailableTimeSlotsDto[]>(
        `${ENDPOINT}/${args.id}/getAvailabilityBySetterTeamId`,
        {
            params: {
                appointmentDate: args.appointmentDate,
            },
        },
    );
    return timeSlots;
};

export const getCloserTeamsAndPolylines = async (): Promise<
    {
        id: number;
        territoryPolygons: any;
        name: string;
    }[]
> => {
    const { data: teams } = await http.get(`${ENDPOINT}/closerTeamsAndPolylines`);
    return teams;
};

export const getTeamsByCoordinateInTerritories = async (
    latitude: number | string,
    longitude: number | string,
): Promise<Team[]> => {
    const { data: teams } = await http.post(`${ENDPOINT}/getTeamsByCoordinateInTerritories`, {
        latitude: latitude.toString(),
        longitude: longitude.toString(),
    });
    return teams;
};
